export default function Textarea( props ) {
  return(
    <>
    <label htmlFor={ props.id }>
    { props.title }
    { props.mandatory ? '*' : '' }
    </label>
    <textarea 
      id={ props.id } 
      name={ props.id } 
      rows={ props.rows }
      placeholder={ props.placeholder } 
      value={ props.values[ props.id ] }
      onChange={ props.handleChange }
    />
    </>
  )
}
