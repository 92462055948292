//import Mesh from '../Fiber/Mesh';

export default function Logo( props ) {
	return(
		<>
	        <div 
        		style={{ 
        			fontFamily:'Inter', color:props.textColor, 
        			fontWeight:'900', fontSize:'24px', lineHeight:'22px',
        			padding:'6px 0 0 0'
        		}} 
    		>
				3D<br/>
				Scanning<br/>
				Company
	        </div>
		</>
	)
}
