import Header from './Header';
import Content from './Content';
import ContactForm from './ContactForm';
import Footer from './Footer';

export default function FrontPage( props ) {
  return (
  <>
    <Header state={ props.state } setState={ props.setState } />
    <main>
      <div style={{ flexGrow:1 }} />
      <div className="centered" >
        <Content/>
        <ContactForm/>
      </div>
      <div style={{ flexGrow:1 }} />
    </main>
    <Footer/>
  </>
  )
}

