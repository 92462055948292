import Logo from '../Logo/Logo';

export default function Header( props ) {
	return(
		<header>
		  <div style={{ flexGrow:1 }} />
		  <div className="centered" style={{ paddingTop:'0' }} >
		    <div style={{ display:'flex' }} >
		      <div style={{ flexGrow:1, fontFamily:'BioRhyme', userSelect:'none', fontWeight:'300' }}  >
		      	<Logo backgroundColor="#e0e0e0" color="#0b344a" helperColor="#000" textColor="#444444" />
		      </div>
		      <div style={{ flexGrow:1, textAlign:'right', padding:'28px 0 0 0', userSelect:'none', color:'#444444', cursor:'pointer', fontSize:'18px', fontWeight:'700' }} >
		      	{/*
		        <a href="https://alidad.art/gallery">
		          <button style={{ cursor:'pointer' }} >
		            Sign&nbsp;in
		          </button>
		        </a>
				*/}
		      </div>

		    </div>
		  </div>
		  <div style={{ flexGrow:1 }} />
		</header>
	)
}
