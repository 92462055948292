import React, { useState } from 'react';
import FrontPage from './FrontPage';
import Presentation from '../Presentation/Presentation';

export default function App() {
  return (
      <FrontPage />
  )
}

