import Logo from '../Logo/Logo';
//import Mesh from '../Fiber/Mesh';

export default function Footer() {
	return(
	    <footer>
	      <div style={{ flexGrow:1 }} />
	      <div className="centered" >

	        <div style={{ margin:'0 0 30px 0' }} >
	        	<br/>
		      	<Logo backgroundColor="#212121" color="#fff" helperColor="#fff" textColor="#bbb" />
		        <br/>
		        <div style={{ opacity:0.65 }} >
			        <a href="mailto:info@scanningcompany.com" style={{ color:'#fff' }} >
			        	info@scanningcompany.com
		        	</a>
			        <br/>
			        <br/>
			        The Scanning Company Finland Ltd<br/>
			        <br/>
			        Katajanokanlaituri 5<br/>
			        00160 Helsinki<br/>
			        Finland<br/>
			        <br/>
			        Corporate registry code: FI-3229030-5
		        </div>
	        </div>

			{/*	          
	        <br/><br/>
	        <a href={ process.env.PUBLIC_URL + '/documents/' + 'Airtouch_General_terms_and_conditions.pdf' } target="_blank" rel="noreferrer" style={{ color:'#f6f1eb' }} >
	          Terms and Conditions 
	        </a>
	        <span style={{ opacity:'0.5' }} >
	          &nbsp;&nbsp;|&nbsp;&nbsp; 
	        </span>
	        <a href={ process.env.PUBLIC_URL + '/documents/' + 'Alidad_Privacy_Policy.pdf' } target="_blank" rel="noreferrer" style={{ color:'#f6f1eb' }} >
	          Privacy Policy 
	        </a>
	        */}


	        
	        <span style={{ opacity:'0.65' }} >
	          © The Scanning Company Finland Ltd 2021. All rights reserved.
	        </span>
	        <div style={{ height:'40px' }} />
	      </div>
	      <div style={{ flexGrow:1 }} />
	    </footer>
	)
}
