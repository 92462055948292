
export default function Input( props ) {
  return(
    <>
    <label htmlFor={ props.id } style={{ color:( props.contact === 2 && props.mandatory ) ? 'red' : '¤444' }} >
    { props.title }
    { props.mandatory ? '*' : '' }
    </label>
    <input 
    	id={ props.id } 
    	name={ props.id } 
    	value={ props.values[ props.id ] }
    	onChange={ props.handleChange }
    	placeholder={ props.placeholder } 
	/>
    </>
  )
}

