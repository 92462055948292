
const slides = [

	{ title:"Introduction",
	image:"Brandhorst_Munich.jpg",
	paragraphs:[
		"This is a presentation of an investment opportunity to Alidad, an extended reality (XR) startup, set to transform transactions of physical art into digital.",
		"Alidad is a division of Airtouch Ltd, an operations company present in Qatar and Finland.",
	],
	notes:[
		"Please use arrow-keys on the top-right corner to move between the slides.",
		//"On a PC you can also navigate using keyboard arrow keys.",
		"Examples in this presentation have been tested to work with Chrome, Safari and Edge, on M1 Macs and PC's with GPU, as well as iPhones, iPads and Androids for the last three generations.",
	] },


	{ title:"Background",
	image:"Guggenheim_Museum_Bilbao.jpg",
	bullets:[
		"Most business sales, marketing and transactions are going online, or already have",
		"Commerce with unique tangible artworks seems to be lagging behind this trend",
		"We see an opportunity in connecting galleries, auctionieers, collectors and artists in novel ways",
	] },


	{ title:"Opportunity",
	image:"Centre_George_Pompidou_Paris.jpg",
	bullets:[
		"Globally 55.000 art museums and galleries",
		"31.4 million art transactions yearly",
		"Total Art Market value 50 billion USD",
	],
	notes:[
		"(2020 by Statista 6-2021)",
	] },


	{ title:"Goal",
	image:"Solomon_R_Guggenheim_Museum_New_York.jpg",
	bullets:[
		"Alidad strives to become the leading platform for online art sales",
		"Essentially the \"Shopify\" for physical artworks",
		"This by offering clients superior features, especially 3D and XR (extended reality)",
		"Additionally also pertinent services, such as 3D scanning of physical artworks",
	] },


	{ title:"Achievements",
	image:"Hamburger_Bahnhof.jpg",
	bullets:[
		"Scanning process, with results approved by leading artists and gallerists in Finland",
		"Automated optimization process for refining raw scans into usable 3D models",
		"Delivery platform distributing extremely detailed and life-like 3D models over standard Web",
		"Viewable on all modern devices, including in Safari on iPhones and iPads",
	] },


	{ title:"Example",
	notes:[
		"Scans and a gallery for Arabia Art Society",
		"Navigate between artworks with arrows in the bottom of the screen.",
	],
	demo:"arabia" },


	{ title:"Benefits",
	image:"The_Louvre_Paris.jpg",
	bullets:[
		"A gallery, museum, auction or artist is able to display and sell artworks in virtual space",
		"Alidad platform can either replace existing website (like Shopify often does) or be attached to it",
		"Galleries can cross sell each others artworks, through their virtual storefronts and gain extra revenue",
		"Collectors can safely display digital versions of their artworks to others, in person or remotely",
		"Virtual artwork can be sold as non fungible tokens (NFT), exclusively or in limited numbers",
	] },


	{ title:"Revenue model",
	image:"MOCA_Los_Angeles.jpg",
	bullets:[
		"Subscriptions, commissions and payment processing.",
		"Services, such as 3D scanning and processing, logistics, advertising and marketing.",
	] },


	{ title:"Other revenue sources",
	image:"Museum_of_Contemporary_Art_of_Rome.jpg",
	bullets:[
		"Commissions from cross selling between storefronts, i.e. one storefront offering works from other storefronts.",
		"Sales or leasing of hardware, such as 3D scanning equipment and lightfield 3D displays, which offer life-like virtual display cabinet experience",
		"Natively digital commerce, such as arranging NFT (non fungible token) drops of digital representations of 3D-scanned artworks",
	] },


	{ title:"Core OpEx pm.",
	image:"Museum_of_Contemporary_Art_Tokyo.jpg",
	bullets:[
		"Equipment lease 850 Euros",
		"Marketing 2.000 Euros",
		"Payroll 5.000 Euros",
		"Insurance 500 Euros",
		"Research & development 5.000 Euros",
	],
	total:"13.350 Euros",
	notes:[
		"Equipment lease consists of one 3D-scanner",
		"In payroll one person, both scanning and processing",
		"25M Indemnity Insurance from Lloyds",
	] },


	{ title:"Target OpEx pm.",
	image:"Tate_Modern_London.jpg",
	bullets:[
		"Rent 4.000 Euros",
		"Equipment lease 2.300 Euros",
		"Marketing 5.000 Euros",
		"Payroll 20.000 Euros",
		"Insurance 1.000",
		"Research & development 15.000 Euros",
	],
	total:"47.300 Euros",
	notes:[
		"50M Indemnity Insurance from Lloyds",
	] },


	{ title:"OpEx step cost pm.",
	image:"Museu_Inhotim_Brumadinho.jpg",
	bullets:[
		"Scanning team 12.000 Euros",
	],
	total:"12.000 Euros",
	notes:[
		"We estimate one scanning team being able to scan and process up to 400 artworks a month.",
		"Therefore first step up cost incurs only when the number of galleries and other active clients exceeds 50.",  
	] },


	{ title:"Itemized revenue pm.",
	image:"The_Reina_Sofia_Museum_Madrid.jpg",
	bullets:[
		"Subscription fee 500",
		"Commission 5%",
		"Payment processing fee 2%",
		"Scanning and processing fee, first scan on site 1.500 Euros, next ones 250 Euros each",
		"Travel avg. 1.000 per day, in Europe",
	] },


	{ title:"Revenue with 10 clients pm.",
	image:"The_Metropolitan_Museum_of_Art_New_York.jpg",
	bullets:[
		"Subscriptions 10*500 = 5.000 Euros",
		"5 scans per gallery 10*2500 = 25.000 Euros",
		"Commission for 10 sales @ 20.000 = 10.000 Euros",
	],
	total:"40.000 Euros pm. 480K Euros pa.",
	notes:[
		"Assumptions:",
		"5 scans per month per client, on average.",  
		"1 sale of artwork of 20.000 Euros value per client per month.",  
	] },


	{ title:"Revenue with 100 clients pm.",
	image:"MoMA_New_York.jpg",
	bullets:[
		"Subscriptions 100*500 = 50.000 Euros",
		"5 scans per gallery 100*2500 = 250.000 Euros",
		"Commission for 100 sales @ 20.000 = 100.000 Euros",
	],
	total:"400.000 Euros pm. 4.8M Euros pa.",
	notes:[
		"Assumptions:",
		"5 scans per month per client, on average.",  
		"1 sale of artwork of 20.000 Euros value per client per month.",  
	] },


	{ title:"Investment opportunity",
	image:"Palais_de_Tokyo_Paris.jpg",
	bullets:[
		"Convertible note 250K Euros",
		"10% of Airtouch Ltd.",
		"Interest 10% p.a.",
		"Duration 3 years",
	] },


	{ title:"Contacts",
	image:"San_Francisco_Museum_of_Modern_Art.jpg",
	bullets:[
		<>Business and investment<br/>Pekka Mäkinen, CEO<br/>pekka.makinen@airtouch.fi<br/>+358 40 570 6760</>,
		<>Technology and operations<br/>Harri Myllynen, CTO<br/>harri.myllynen@airtouch.fi<br/>+358 44 9300 950</>,
	] },

]

export default slides
