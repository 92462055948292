
export default function Content() {
	return(
		<>

			<h2 style={{ marginTop:'40px' }} >
				Introduction
			</h2>
			<p>The Scanning Company Finland introduces a novel process for product photography and videos.</p>
			<p>Instead of studio photography, the first in Finland, we 3D-scan the product and packaging, out of which we gerenate product images and videos.</p>
			<p>3D-scanning produces more realistic digital twins of actual products, compared to those modelled manually or from CAD, because of high-precision scanning realistically reproduces also all the artefacts and imperfections of actual objects.</p>
			<p>It actually benefits you, even in case you have all the geometries and materials already at hand, from SolidWorks, AutoCad, or other sources.</p>
			<p>The quality is similar to that of the best photo shoots, but without the cost and hassle of post processing.</p>
			{/*
			<div style={{ background:'#666', color:'#fff', padding:'60px 20px' }} >Gallery, of images and a video of Chocos's buiquits</div>
			*/}
			<p>In addition, a true to life 3D-model is available for you to use in Web (.glb) and AR (.usdz):</p>

			<iframe frameBorder="0" style={{ width:'100%', height:'100%', minHeight:'480px' }} src="https://scanningcompany.com/choco"></iframe>

			{/*
			<div style={{ background:'#666', color:'#fff', padding:'60px 20px' }} >3D viewer, of Chocos's buiquits</div>
			*/}

			<h2 style={{ margin:'60px 0 30px 0' }} >
				Add value for years to come
			</h2>
			<p>The produced 3D-model can also be used for virtual (cgi/vfx) photo shoots and marketing videos for years to come.</p>
			<p>Yet the cost of our process is similar to that of the traditional studio photography.</p>

			<h2 style={{ margin:'60px 0 30px 0' }} >
				Contact Us
			</h2>
			<p>If you are interested in ordering 3D-scans or collaborating, please fill in the form below and we will be in contact with you soon.</p>
			<p>&nbsp;</p>
			<p>We are looking forward to working with you!</p>

		</>
	)
}
